import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

export default function PoemTemplate({ data, pageContext }) {
  const { title, slug, createdAt, poemImage } = data.contentfulPoem
  const renderPoemBody = () =>
    documentToReactComponents(
      data.contentfulPoem.childContentfulPoemPoemBodyRichTextNode.json
    )

  const imgSrc =
    poemImage && poemImage.fluid && poemImage.fluid.src
      ? poemImage.fluid.src
      : ""

  const renderImage = () =>
    imgSrc ? <img className="poem-pg-img" src={imgSrc} /> : null

  return (
    <Layout>
      <SEO title={`${title} | Poem`} />
      <Helmet>
        <link
          rel="canonical"
          href={`http://www.gabriellaalziari.com/${slug}`}
        />
      </Helmet>
      <div className="poem-container">
        <div className="poem-pg-wrap">
          <div className="poem-pg-title">{title}</div>
          {renderImage()}
          <div className="poem-pg-body">{renderPoemBody()}</div>
        </div>
      </div>
    </Layout>
  )
}

export const pageQuery = graphql`
  query ContentfulPoemBySlug($slug: String!) {
    contentfulPoem(slug: { eq: $slug }) {
      title
      slug
      createdAt
      childContentfulPoemPoemBodyRichTextNode {
        json
      }
      poemImage {
        fluid {
          src
        }
      }
    }
  }
`
